import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/joy/Typography';

function FirebaseUser({
  displayName,
  email,
  emailStatus,
  photoURL,
  authProviderData,
  providerIds,
}) {
  return (
    <>
      <Grid item xs={4}>
        <Typography level="h5">Firebase</Typography>
        {/* Name */}
        <Typography level="body2">
          {displayName} {!displayName && '-'}
        </Typography>
        {/* Email */}
        <Typography level="body2">
          {email && `${email} (${emailStatus})`} {!email && '-'}
        </Typography>
        {photoURL && <img src={photoURL} alt="user" />}
      </Grid>
      <Grid item xs={4}>
        <Typography level="h5">Provider Data</Typography>
        <Typography level="body2">
          {authProviderData?.displayName}
          {!authProviderData.displayName && '-'}
        </Typography>
        <Typography level="body2">
          {authProviderData?.email}
          {!authProviderData.email && '-'}
        </Typography>
        {authProviderData.photoURL && (
          <img src={authProviderData.photoURL} alt="user" />
        )}
        <Typography level="h6">{providerIds}</Typography>
      </Grid>
    </>
  );
}

export default FirebaseUser;
