import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getAuth,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from 'firebase/auth';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Paper,
  Alert,
  CircularProgress,
  Stack,
} from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';

function PasswordResetPage() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [oobCode, setOobCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [validCode, setValidCode] = useState(false);

  const location = useLocation();
  const auth = getAuth();

  // Extract oobCode from URL on component mount
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('oobCode');

    if (!code) {
      setError('Invalid password reset link. Please request a new one.');
      setLoading(false);
      return;
    }

    setOobCode(code);

    // Verify the password reset code
    verifyPasswordResetCode(auth, code)
      .then(() => {
        setValidCode(true);
        setLoading(false);
      })
      .catch(() => {
        setError(
          'This password reset link has expired or is invalid. Please request a new one.',
        );
        setLoading(false);
      });
  }, [location, auth]);

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      setError('');

      // Validate passwords
      if (newPassword !== confirmPassword) {
        setError('Passwords do not match');
        return;
      }

      if (newPassword.length < 6) {
        setError('Password must be at least 6 characters');
        return;
      }

      try {
        setLoading(true);
        await confirmPasswordReset(auth, oobCode, newPassword);
        setSuccess(true);
        setLoading(false);
        // Optionally redirect after a delay
        // setTimeout(() => navigate('/login'), 3000);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    },
    [auth, confirmPassword, newPassword, oobCode],
  );

  const handleSetNewPassword = React.useCallback(
    (e) => setNewPassword(e.target.value),
    [],
  );

  const handleSetConfirmPassword = React.useCallback(
    (e) => setConfirmPassword(e.target.value),
    [],
  );

  return (
    <Container maxWidth="sm" sx={{ mt: 8, mb: 8 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
            <LockResetIcon color="primary" sx={{ fontSize: 40, mr: 1 }} />
            <Typography component="h1" variant="h4" color="primary">
              Reset Password
            </Typography>
          </Box>

          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
              <CircularProgress />
            </Box>
          ) : success ? (
            <Stack spacing={2} sx={{ width: '100%' }}>
              <Alert severity="success">
                Your password has been successfully reset!
              </Alert>
              <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
                Please return to the mobile app and login with your new
                password.
              </Typography>
            </Stack>
          ) : error && !validCode ? (
            <Stack spacing={2} sx={{ width: '100%' }}>
              <Alert severity="error">{error}</Alert>
            </Stack>
          ) : (
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ width: '100%', mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="New Password"
                type="password"
                id="new-password"
                autoComplete="new-password"
                value={newPassword}
                onChange={handleSetNewPassword}
                sx={{ mb: 2 }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirm-password"
                value={confirmPassword}
                onChange={handleSetConfirmPassword}
                sx={{ mb: 2 }}
              />

              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, py: 1.5 }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Reset Password'}
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
    </Container>
  );
}

export default PasswordResetPage;
