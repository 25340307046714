import React from 'react';
import { useNavigate } from 'react-router-dom';
// Material UI
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
//
import {
  XS_CARD_WIDTH,
  MD_CARD_WIDTH,
  LG_CARD_WIDTH,
} from '../../utils/constants';
import FirebaseUser from './FirebaseUser'; // Import the new component

function User({ user }) {
  const {
    displayName,
    email,
    emailVerified,
    customClaims,
    photoURL,
    providerData,
    ittUser,
    metadata,
    uid,
  } = user;

  const navigate = useNavigate();
  const handleEditUser = React.useCallback(
    () => navigate(`/user/${ittUser.id}`),
    [ittUser.id, navigate],
  );

  // create an email status
  const emailStatus = emailVerified ? 'Verified' : 'Not Verified';
  // get the provider data
  let providerIds = '';
  let authProviderData = {};
  if (providerData && providerData.length > 0) {
    providerIds = providerData
      .map((provider) => provider.providerId)
      .join(', ');
    authProviderData = providerData[0];
  }

  return (
    <Card
      row
      variant="outlined"
      sx={{
        minWidth: {
          xs: XS_CARD_WIDTH,
          md: MD_CARD_WIDTH,
          lg: LG_CARD_WIDTH,
        },
        width: {
          xs: XS_CARD_WIDTH,
          md: MD_CARD_WIDTH,
          lg: LG_CARD_WIDTH,
        },
        margin: '4px',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography level="h4">{uid}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography level="h5">ITT User Data</Typography>
          <Typography level="body2">
            {ittUser?.displayName}
            {!ittUser?.displayName && '-'}
          </Typography>
          <Typography level="body2">
            {ittUser?.email}
            {!ittUser?.email && '-'}
          </Typography>
          {ittUser?.photoURL && <img src={photoURL} alt="user" />}
          <Typography level="body2">
            {ittUser?.firstName}
            {!ittUser?.firstName && '-'}
          </Typography>
          <Typography level="body2">
            {ittUser?.lastName}
            {!ittUser?.lastName && '-'}
          </Typography>
          <Typography level="body2">
            {ittUser?.provider}
            {!ittUser?.provider && '-'}
          </Typography>
        </Grid>
        <FirebaseUser
          displayName={displayName}
          email={email}
          emailStatus={emailStatus}
          photoURL={photoURL}
          authProviderData={authProviderData}
          providerIds={providerIds}
        />
        <Grid item xs={12}>
          <Typography level="h5">Metadata</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography level="body2">
            Claims: {JSON.stringify(customClaims, null, 1)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography level="body2">
            Created: {metadata?.creationTime}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography level="body2">
            Refreshed: {metadata?.lastRefreshTime}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography level="body2">
            Logged In: {metadata?.lastSignInTime}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {ittUser?.id && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditUser}
              style={{ margin: '5px' }}
            >
              Edit User
            </Button>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

export default User;
