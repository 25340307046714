import { useState, useEffect } from 'react';
// firestore
// import { query, collection, where, getDocs } from 'firebase/firestore';
import { doc, getDoc, Timestamp } from 'firebase/firestore';
import { db } from '../utils/firebase';

// custom hook to retrieve a user

function useUser(id) {
  const [user, setUser] = useState(null);
  /* function to get a brewery from the DB */
  useEffect(() => {
    const getUser = async () => {
      /* function to get a brewery from the DB */

      const item = {};
      const userDocRef = doc(db, 'users', id);
      const udr = await getDoc(userDocRef);
      const ud = udr.data();
      for (const field in ud) {
        if (Object.prototype.hasOwnProperty.call(ud, field)) {
          const value =
            ud[field] instanceof Timestamp ? ud[field].toDate() : ud[field];
          item[field] = value;
        }
      }
      setUser(item);
    };

    getUser();
  }, [id]);

  console.log('user:', user);
  return user;
}

export default useUser;
