// JavaScript
// src/breweries/Breweries
import React from 'react';
import { useState, useEffect } from 'react';
import { listUsers } from '../../utils/firebase';
//
import Grid from '@mui/material/Grid';
// Components
import IttAppBar from '../../components/app/IttAppBar';
import User from './User';
import PageLoadingIndicator from '../../components/widgets/PageLoadingIndicator';

function Users() {
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const pageTitle = 'Users';

  /* function to get all tasks from firestore in realtime */
  useEffect(() => {
    let isSubscribed = true;

    const getUsers = async () => {
      const result = await listUsers();
      const { data } = result;
      if (data.error) {
        console.error(data.error);
        setError(true);
      }
      const { users: usersList } = data;
      // retrn users;
      if (isSubscribed) {
        setUsers(usersList);
        setLoading(false);
      }
    };
    getUsers().catch(console.error);

    return () => (isSubscribed = false);
  }, [setUsers]);

  if (isLoading) {
    return <PageLoadingIndicator title={pageTitle} />;
  }
  if (isError) {
    return (
      <>
        <IttAppBar title={pageTitle} />
        <h1>There was an error</h1>;
      </>
    );
  }

  return (
    <div>
      <IttAppBar title={pageTitle} />

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        {users.map((user) => (
          <User key={user.uid} user={user} />
        ))}
      </Grid>
    </div>
  );
}

export default Users;
