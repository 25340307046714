import React, { useCallback } from 'react';
import * as Sentry from '@sentry/browser';
// firebase
import { db } from '../../utils/firebase';
import { doc, setDoc } from 'firebase/firestore';
//hooks
import useBrewerySnapshot from '../../hooks/useBrewerySnapshot';
import useCompanyLocationOverride from '../../hooks/useCompanyLocationOverride';
// import { useParams } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
// functions
import cleanOverride from './overrideBrewery/cleanOverride';
// material ui
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
// components
import Alert from '../../components/widgets/Alert';
import BreweryLogo from './BreweryLogo';
import BreweryDeets from './BreweryDeets';
import ButtonHeader from '../../components/widgets/ButtonHeader';
import DetailsFormHandler from './overrideBrewery/detailsForm/DetailsFormHandler';
import FormDivider from '../../components/widgets/FormDivider';
import IttAppBar from '../../components/app/IttAppBar';
import PageLoadingIndicator from '../../components/widgets/PageLoadingIndicator';
import BreweryAmenities from './BreweryAmenities';
// icons
import ArrowBack from '@mui/icons-material/ArrowBack';
import QuestionMarkOutlined from '@mui/icons-material/QuestionMarkOutlined';

function BreweryProfileGrid({ brewery }) {
  const {
    address1,
    city,
    name,
    logoUrl,
    state,
    zip,
    phone,
    website,
    facebook,
    twitter,
    isOob,
    oobDate,
    email = '',
    socials,
    amenities = [],
  } = brewery;

  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12} md={12}>
        <BreweryDeets
          name={name}
          address1={address1}
          city={city}
          state={state}
          zip={zip}
          phone={phone}
          website={website}
          facebook={facebook}
          twitter={twitter}
          isOob={isOob}
          oobDate={oobDate}
          email={email}
          socials={socials}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <BreweryAmenities amenities={amenities} />
      </Grid>
      <Grid item xs={12} md={12}>
        <BreweryLogo logoUrl={logoUrl} />
      </Grid>
    </Grid>
  );
}

function HelpText({ openPopover, anchorEl, handlePopoverClose }) {
  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: 'none',
      }}
      open={openPopover}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Typography sx={{ p: 1 }}>
        <span>
          I&apos;d Tap That brewery profiles are constructed from public data
          and website crawlers.
        </span>
        <br />
        <span>
          Occasionally the information may be innacurrate or outdated. In that
          case you can use this page to override the data with better values.
        </span>
        <br />
        <span>Enter overrides in the fields on the right and click save.</span>
        <br />
        <span>
          The profile on the left will be updated with your changes momentarily.
        </span>
      </Typography>
    </Popover>
  );
}

function BackButton({ handleBackNavigaton }) {
  return (
    <Button onClick={handleBackNavigaton} style={{ margin: '5px' }}>
      <ArrowBack />
      Back to List
    </Button>
  );
}

function QuestionMarkButton({ handlePopoverOpen, handlePopoverClose }) {
  return (
    <Button
      color="secondary"
      style={{ margin: '5px' }}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <QuestionMarkOutlined />
    </Button>
  );
}

function ButtonHeaderGrid({ handleBackNavigaton }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const openPopover = Boolean(anchorEl);
  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <ButtonHeader>
          <Grid
            container
            spacing={2}
            item
            xs={12}
            justifyContent={'space-between'}
          >
            <BackButton handleBackNavigaton={handleBackNavigaton} />
            <QuestionMarkButton
              handlePopoverOpen={handlePopoverOpen}
              handlePopoverClose={handlePopoverClose}
            />

            <HelpText
              handlePopoverClose={handlePopoverClose}
              anchorEl={anchorEl}
              openPopover={openPopover}
            />
          </Grid>
        </ButtonHeader>
      </Grid>
    </Grid>
  );
}

function DetailsFormGrid({
  brewery,
  override,
  handleUpdate,
  handleBackNavigaton,
}) {
  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <DetailsFormHandler
          brewery={brewery}
          override={override}
          handleUpdate={handleUpdate}
          handleBackNavigaton={handleBackNavigaton}
        />
      </Grid>
    </Grid>
  );
}

function BreweryOverrideContainer({
  brewery,
  override,
  handleUpdate,
  handleBackNavigaton,
}) {
  return (
    <Container maxWidth={false}>
      <Grid container p={2} spacing={2}>
        <Grid item xs={6} md={6}>
          <ButtonHeaderGrid handleBackNavigaton={handleBackNavigaton} />
          <FormDivider title="Brewery Profile" />
          <BreweryProfileGrid brewery={brewery} />
        </Grid>
        <Grid item xs={6} md={6}>
          <DetailsFormGrid
            brewery={brewery}
            override={override}
            handleUpdate={handleUpdate}
            handleBackNavigaton={handleBackNavigaton}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

function Brewery() {
  const { id } = useParams();
  const navigate = useNavigate();
  const brewery = useBrewerySnapshot(id);
  const override = useCompanyLocationOverride(id);
  const pageTitle = 'Brewery Manager';
  const [successOpen, setSuccessOpen] = React.useState(false);

  const handleBackNavigaton = useCallback(() => {
    navigate('/breweries');
  }, [navigate]);

  const handleCloseSnackbar = useCallback(() => {
    setSuccessOpen(false);
  }, [setSuccessOpen]);

  /* function to update document in firestore
   *
   *  the logic in this function is a little complicated because we need to remove empty values
   *  the saved override object should contain only the values that are being overridden
   *
   *  @param {object} values - the values object from formik
   */
  const handleUpdate = useCallback(
    async (values, setSubmitting) => {
      //clone the values object so we can remove empty values withhout messing up the form
      const valuesCloned = await structuredClone(values);
      //remove empty values from the clone
      const valuesCleaned = await cleanOverride(valuesCloned, brewery);
      try {
        valuesCleaned.parentBreweryId = id;
        const overrideDocRef = doc(db, 'breweryOverrides', id);
        await setDoc(overrideDocRef, valuesCleaned);
        setSuccessOpen(true);
        setSubmitting(false);
      } catch (err) {
        Sentry.captureException(err);
      }
    },
    [id, brewery],
  );

  if (brewery === null) {
    return <PageLoadingIndicator title={pageTitle} />;
  }

  return (
    <>
      <IttAppBar title={pageTitle} />
      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        severity="success"
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: '100%' }}
        >
          Changes saved successfully
        </Alert>
      </Snackbar>
      <BreweryOverrideContainer
        brewery={brewery}
        override={override}
        handleUpdate={handleUpdate}
        handleBackNavigaton={handleBackNavigaton}
      />
    </>
  );
}

export default Brewery;
