import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../utils/firebase';
//material UI
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
// hooks
import useUser from '../../hooks/useUser';
// components
import Alert from '../../components/widgets/Alert';
import IttAppBar from '../../components/app/IttAppBar';
import PageLoadingIndicator from '../../components/widgets/PageLoadingIndicator';
import ButtonHeader from '../../components/widgets/ButtonHeader';

const EditUserForm = () => {
  const { id } = useParams();
  const user = useUser(id);

  const pageTitle = 'Edit User';
  const [successOpen, setSuccessOpen] = useState(false);

  /* function to update document in firestore
   *
   *  the logic in this function is a little complicated because we need to remove empty values
   *  the saved override object should contain only the values that are being overridden
   *
   *  @param {object} values - the values object from formik
   */
  const handleSubmit = useCallback(
    async (values, setSubmitting) => {
      console.log('values', values);
      try {
        values.id = id;
        const userDocRef = doc(db, 'users', id);
        await setDoc(userDocRef, values);
        setSuccessOpen(true);
        setSubmitting(false);
      } catch (err) {
        Sentry.captureException(err);
      }
    },
    [id],
  );

  const handleCloseSnackbar = useCallback(() => {
    setSuccessOpen(false);
  }, [setSuccessOpen]);

  const handleBackButton = useCallback(() => {
    window.history.back();
  }, []);

  if (!user) {
    return <PageLoadingIndicator title={pageTitle} />;
  }
  // destructuring user object
  const {
    displayName = '',
    email = '',
    firstName = '',
    lastName = '',
    provider = '',
    photoURL = '',
  } = user;

  const initialValues = {
    displayName,
    email,
    firstName,
    lastName,
    provider,
    photoURL,
  };

  const validationSchema = Yup.object({
    displayName: Yup.string().required('Name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    // Add other user fields validation here
  });

  return (
    <>
      <IttAppBar title={pageTitle} />
      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        severity="success"
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: '100%' }}
        >
          Changes saved successfully
        </Alert>
      </Snackbar>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, submitForm, isValid }) => (
          <Grid container p={2} spacing={2}>
            <Grid spacing={2} item xs={12} justifyContent={'space-around'}>
              <ButtonHeader>
                <Grid spacing={2} item xs={12} justifyContent={'space-around'}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || !isValid}
                    onClick={submitForm}
                    style={{ margin: '5px' }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleBackButton}
                    style={{ margin: '5px' }}
                  >
                    Back
                  </Button>
                </Grid>
              </ButtonHeader>
            </Grid>
            <Grid item xs={2} spacing={2} justifyContent={'space-around'} />
            <Grid item spacing={2} xs={8} justifyContent={'space-around'}>
              <Form>
                <Field
                  component={TextField}
                  name="displayName"
                  type="string"
                  label="Display Name"
                  variant="standard"
                  fullWidth
                />
                <ErrorMessage name="displayName" component="div" />
                <Field
                  component={TextField}
                  name="firstName"
                  type="string"
                  label="First Name"
                  variant="standard"
                  fullWidth
                />
                <ErrorMessage name="firstName" component="div" />
                <Field
                  component={TextField}
                  name="lastName"
                  type="string"
                  label="Last Name"
                  variant="standard"
                  fullWidth
                />
                <ErrorMessage name="lastName" component="div" />
                <Field
                  component={TextField}
                  name="provider"
                  type="string"
                  label="Provider"
                  variant="standard"
                  fullWidth
                />
                <ErrorMessage name="provider" component="div" />
                <Field
                  component={TextField}
                  type="string"
                  label="Email"
                  name="email"
                  variant="standard"
                  fullWidth
                />
                <ErrorMessage name="email" component="div" />
                <Field
                  component={TextField}
                  type="string"
                  label="Photo URL"
                  name="photoURL"
                  variant="standard"
                  fullWidth
                />
                <ErrorMessage name="email" component="div" />
                {/* </Grid> */}
              </Form>
            </Grid>
            <Grid item xs={2} spacing={2} />
          </Grid>
        )}
      </Formik>
    </>
  );
};

export default EditUserForm;
